<template>
  <div style="height: 100vh">
    <!-- Used to anchor book recommendations -->
    <div class="wrapper column"  ></div>
    <oib-nav-student :show_backdrop="true">
     <template v-slot:right v-if="isbn13_scanned && has_role(['TEACHER', 'LIBRARY_ASSISTENT', 'LIBRARIAN', 'LIBRARY_PARTNER', 'SYSTEM_ADMIN'])">
        <oib-button-icon
        style="font-size: 24px;"
        icon="edit"
        :has_backdrop="true"
        @click="go_to_book_menu" />
      </template>
    </oib-nav-student>
    <div style="position: fixed; z-index: 20; top: 180px;" :style="`right: ${tag_list_right_position}px`">
        <div>
          <!-- <img
          v-if="has_role(['LIBRARY_PARTNER', 'SYSTEM_ADMIN'])"
          src="../assets/svg/add_grey.svg"
          alt=""
          class="tag-tile clickable"
          :style="`transform: translate(${(tag_list.length - 1) * 50 - 55 * (-1 - 1)}px, ${30 - 50 * -1}px) rotate(15deg)`"
          @click="go_to_boek_stemmen('BoekStemmenTrefwoorden')"
          /> -->
          <img
          v-for="(tag, i) in tag_list"
          :key="i"
          :src="tag.img"
          :alt="i"
          class="tag-tile"
          :style="`transform: translate(${(tag_list.length - 1) * 50 - 55 * (i - 1)}px, ${30 - 50 * i}px) rotate(15deg)`"
          />
        </div>
    </div>
    <oib-page-book v-if="display_main_screen && isbn13" :isbn13="isbn13">
      <template v-slot:right>
        <div class="book-info fade" :style="loaded ? 'opacity: 1' : 'opacity: 0'">
          <div class="transition">
            <div class="wrapper column average" style="height: 100%; padding-bottom: 0px;">
              <oib-header-book
              :isbn13="isbn13"
              :user_id="user_id"
              :book_title="book_title"
              :author_name="author_name"
              />
              <div
              class="row"
              style="justify-content: space-between; margin-top: 40px;">
                <oib-button
                shape="rounded"
                fill="stretch"
                @click="button_action"
                >{{button_text}}</oib-button>
               </div>
              <div>
                <div class="row subtext book-details">
                  <div>
                    <p>Categorie</p>
                    <p style="text-transform: capitalize">{{book_type}}</p>
                  </div>
                  <p>|</p>
                  <div>
                    <p>Bladzijdes</p>
                    <p>{{ page_count }}</p>
                  </div>
                  <p>|</p>
                  <div>
                    <p>Doelgroep</p>
                    <p>{{target_audience}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="wrapper column average">
                <h3>Beschrijving</h3>
                <div class="description">
                  <p class="subtext">{{series}}</p>
                  <p class="subtext"> {{ tags }} </p>
                  <div class="subtext" v-html="summary"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:bottom>
        <section style="background-color: var(--primary-color); width: 100%; z-index: 2">
          <div class="wrapper column">
            <h2 v-if="tile_list_recommendations && tile_list_recommendations.length > 0" class="h2" style="margin-bottom: 20px;margin-top: 40px;">Meer zoals dit</h2>
          </div>
          <oib-book-swiper
          v-if="tile_list_recommendations && tile_list_recommendations.length > 0"
          :user_id="user_id"
          :tile_list="tile_list_recommendations"
          :marker_width="80"
          style="padding-left: 5%"
          page="Boek"
          />
          <div class="wrapper column" v-show="scanned === 'true'">
            <h2 class="h2" style="margin-bottom: 20px;margin-top: 40px;">Barcode</h2>
            <oib-barcode :isbn13="isbn13" ref="barcode"/>
            <oib-button
              style="height: 30px; width: 210px; margin-top: 6px;"
              priority="p1"
              @click="download_barcode"
              >Download barcode ➜
            </oib-button>
          </div>
        </section>
        <!-- <p class="subtext" style="width: 100%; text-align: center">2000-01-01</p> -->
      </template>
    </oib-page-book>
  </div>
</template>

<script>
import OibNavStudent from "../components/OibNavStudent.vue";
import OibPageBook from '../components/oib_page_book.vue'
import OibBookSwiper from '../components/OibBookSwiper.vue'
import OibHeaderBook from '../components/oib_header_book.vue'
import OibButton from '../components/OibButton.vue'
import OibButtonIcon from '../components/OibButtonIcon.vue'
import OibBarcode from '../components/oib_barcode.vue'

import {pick_book} from "../store/library.js"
import {get_libraries_books_copies} from '../store/api/library.js'
import {put_libraries_books_copies_users_return} from '../store/api/library.js'
import {get_book_cover} from "../store/api/library.js"
import {load_book_recommendations} from "../store/book_recommendation.js"
import { get_bucket } from '../store/bucket';
import { get_edition_isbn13_list } from '../store/library'
import { get_datetime_str } from '../store/utils'
import { has_role } from '../store/utils'
import { go_to_route } from "../store/navigation";
import { get_can_borrow } from '../store/library'

export default {
  name: "Boek",
  props: {
    user_id: {
      required: true 
    },
    isbn13: {
      required: true
    },
    book_edition_id: {
      required: true
    },
    scanned: {
      required: false,
      default: false
    }
  },
  components: {
    OibNavStudent,
    OibPageBook,
    OibBookSwiper,
    OibHeaderBook,
    OibButton,
    OibButtonIcon,
    OibBarcode
  },
  data () {
    return {
      display_main_screen: true,
      book_edition_id_list: [],
      isbn13_list: [],
      isbn13_list_recommendations: [],
      isbn13_scanned: null,
      library_id: null,
      book_title: "???",
      author_name: "???",
      page_count: '???',
      target_audience: '-',
      tag_list: [],
      book_type: '-',
      series: '',
      tags: '',
      rating: 4,
      summary: "",
      tag_list_right_position: 40,
      AVI_CONVERTER: {
        0: 'start',
        1: 'm3',
        2: 'e3',
        3: 'm4',
        4: 'e4',
        5: 'm5',
        6: 'e5',
        7: 'm6',
        8: 'e6',
        9: 'm7',
        10: 'e7',
        11: 'plus'
      },
      state: "",
      tile_list_recommendations: [],
      loaded: false,
      tag_dict_filter: {
        'magie en fantasie': {
          'name': 'Magie & fantasie',
          'img': require('@/assets/svg/magie.svg'),
          'color': '#0081C9',
          'tag_name': 'magie en fantasie',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        'sprookjes': {
          'name': 'Sprookjes',
          'img': require('../assets/svg/crown.svg'),
          'color': '#F99F38',
          'tag_name': 'sprookjes',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        'detective': {
          'name': 'Detective',
          'img': require('../assets/svg/detective.svg'),
          'color': '#567189',
          'tag_name': 'detective',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        'science fiction': {
          'name': 'Science-fiction',
          'img': require('../assets/svg/science_fiction.svg'),
          'color': '#242F9B',
          'tag_name': 'science fiction',
          'min_tag_relevance': 0.1,
          'book_type': ['LEESBOEK','AANWIJSBOEK','PRENTENBOEK','STRIPBOEK','VOORLEESBOEK','DICHTBUNDEL','ZOEKBOEK']
        },
        'dieren': {
          'name': 'Dieren',
          'img': require('../assets/svg/tiger_face.svg'),
          'color': '#1F8A70',
          'tag_name': 'dieren',
          'min_tag_relevance': 0.41,
          'book_type': null
        },
        'griezelig': {
          'name': 'Griezel',
          'img': require('../assets/svg/ghost.svg'),
          'color': '#6F38C5',
          'tag_name': 'griezelig',
          'min_tag_relevance': 0.35,
          'book_type': null
        },
        'sport': {
          'name': 'Sport',
          'img': require('../assets/svg/sport.svg'),
          'color': '#A62349',
          'tag_name': 'sport',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        'oorlog': {
          'name': 'Oorlog',
          'img': require('../assets/svg/war.svg'),
          'color': '#8B7E74',
          'tag_name': 'oorlog',
          'min_tag_relevance': 0.1,
          'book_type': null
        },
        'geschiedenis': {
          'name': 'Geschiedenis',
          'img': require('../assets/svg/greek_temple.svg'),
          'color': '#D0B8A8',
          'tag_name': 'geschiedenis',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        'aardrijkskunde': {
          'name': 'Aardrijkskunde',
          'img': require('../assets/svg/earth.svg'),
          'color': '#5BC0F8',
          'tag_name': 'aardrijkskunde',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        'kunst en cultuur': {
          'name': 'Kunst & cultuur',
          'img': require('../assets/svg/art.svg'),
          'color': '#FD8A8A',
          'tag_name': 'kunst en cultuur',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        'avontuur': {
          'name': 'Avontuur',
          'img': require('../assets/svg/adventure.svg'),
          'color': '#d49d5b',
          'tag_name': 'avontuur',
          'min_tag_relevance': 0.5,
          'book_type': null
        },
        'superhelden': {
          'name': 'Superhelden',
          'img': require('../assets/svg/superhero.svg'),
          'color': '#CD0404',
          'tag_name': 'superhelden',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        // 'vakantie': {
        //   'name': 'Vakantie',
        //   'img': require('../assets/svg/holiday.svg'),
        //   'color': '#FFC93C',
        //   'tag_name': 'vakantie',
        //   'min_tag_relevance': 0.2
        // },
        // 'lente': {
        //   'name': 'Lente',
        //   'img': require('../assets/svg/sapling.svg'),
        //   'color': '#82CD47',
        //   'tag_name': 'lente',
        //   'min_tag_relevance': 0.2
        // },
        // 'zomer': {
        //   'name': 'Zomer',
        //   'img': require('../assets/svg/sun.svg'),
        //   'color': '#5BC0F8',
        //   'tag_name': 'zomer',
        //   'min_tag_relevance': 0.2
        // },
        'liefde en relaties': {
          'name': 'Liefde en relaties',
          'img': require('../assets/svg/liefde_en_relaties.svg'),
          'color': '#FF8B13',
          'tag_name': 'liefde en relaties',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        // 'winter': {
        //   'name': 'Winter',
        //   'img': require('../assets/svg/snowman.svg'),
        //   'color': '#E1D7C6',
        //   'tag_name': 'winter',
        //   'min_tag_relevance': 0.4
        // },
        'feest': {
          'name': 'Feest',
          'img': require('../assets/svg/party.svg'),
          'color': '#E64848',
          'tag_name': 'feest',
          'min_tag_relevance': 0.1,
          'book_type': null
        },
        'wetenschap en techniek': {
          'name': 'Wetenschap',
          'img': require('../assets/svg/science.svg'),
          'color': '#00FFD1',
          'tag_name': 'wetenschap en techniek',
          'min_tag_relevance': 0.2,
          'book_type': ['INFORMATIEF'],
        },
        'grappig': {
          'name': 'Humor',
          'img': require('../assets/svg/laughing_face.svg'),
          'color': '#FF74B1',
          'tag_name': 'grappig',
          'min_tag_relevance': 0.4,
          'book_type': null
        },
        'school': {
          'name': 'School verhaal',
          'img': require('../assets/svg/backpack.svg'),
          'color': '#0081B4',
          'tag_name': 'school',
          'min_tag_relevance': 0.4,
          'book_type': null
        },
        // 'lichaam en gezondheid': {
        //   'name': 'Gezondheid',
        //   'img': require('../assets/svg/lungs.svg'),
        //   'color': '#F48484',
        //   'tag_name': 'lichaam en gezondheid',
        //   'min_tag_relevance': 0.1
        // },
        'eten en drinken': {
          'name': 'Eten & drinken',
          'img': require('../assets/svg/food.svg'),
          'color': '#1F8A70',
          'tag_name': 'eten en drinken',
          'min_tag_relevance': 0.5,
          'book_type': null
        },
        'krijgers': {
          'name': 'Krijgers verhaal',
          'img': require('../assets/svg/swords.svg'),
          'color': '#8a123e',
          'tag_name': 'krijgers',
          'min_tag_relevance': 0.31,
          'book_type': null
        },
        'misdaad': {
          'name': 'Misdaad',
          'img': require('../assets/svg/policeman.svg'),
          'color': '#126c8a',
          'tag_name': 'misdaad',
          'min_tag_relevance': 0.3,
          'book_type': null
        },
        'geloof en religie': {
          'name': 'Religie',
          'img': require('../assets/svg/candle.svg'),
          'color': '#2faad4',
          'tag_name': 'geloof en religie',
          'min_tag_relevance': 0.2,
          'book_type': null
        },
        'nederland': {
          'name': 'Nederland',
          'img': require('../assets/svg/dutch_flag.svg'),
          'color': '#FFA500',
          'tag_name': 'nederland',
          'min_tag_relevance': 0.5,
          'book_type': null
        },
        'beroepen': {
          'name': 'Beroepen',
          'img': require('../assets/svg/teacher.svg'),
          'color': '#3e73e6',
          'tag_name': 'beroepen',
          'min_tag_relevance': 0.5,
          'book_type': null
        }
      }
    }
  },
  computed: {
    button_text() {
      if (this.state == "PICK_LIBRARY") return "Bieb kiezen"
      if (this.state == "PICK_BOOK") return "Boek kiezen"
      if (this.state == "BORROW_BOOK") return "Boek lenen"
      if (this.state == "RETURN_BOOK") return "Inleveren"
      return "-"
    },
    show_nbd_labels() {
      const id = this.$store.getters.get_organisation_id_active
      return [269, 308, 346, 298, 315, 333, 350, 373, 378, 409, 436, 443, 444, 460, 461, 467, 473, 475, 491, 511, 515, 535, 538].includes(id)
    }
  },
  watch: {
    isbn13() {
      // Reset page if book changed
      window.scrollTo(0, 0);
      this.init()
      this.loadDescription();
    }
  },
  methods: {
    // import 
    has_role,
    go_to_route,

    // local
    download_barcode() {
      this.$refs.barcode.download()
    },
    go_to_boek_stemmen(name) {
      const user = this.$store.getters.getUser
      this.$router.push({
        name: name,
        params: {
          user_id: user['user_id'],
          isbn13: this.isbn13
        }
      });
    },
    compute_state() {

      if (!has_role(['STUDENT', 'TEACHER', 'LIBRARY_ASSISTENT', 'LIBRARIAN', 'BOOKSHOP_OWNER', 'READING_CONSULTANT', 'SYSTEM_ADMIN'])) {
        return ""
      }
      const user_id_self = this.$store.getters.getUser['user_id']
      if (this.user_id == user_id_self && has_role(['SYSTEM_ADMIN', 'READING_CONSULTANT', 'BOOKSHOP_OWNER'] )) {
        return "PICK_LIBRARY"
      }

      // Get finished state
      const progress_state_dict = this.$store.getters.get_progress_state_dict[this.user_id];
      const is_reading = this.is_intersection(new Set(this.isbn13_list), progress_state_dict[1])

      // Return a book
      const active_library_id = this.$store.getters.get_active_library_id

      let is_borrowing = false
      let books_borrowed = this.$store.getters.get_books_borrowed[this.user_id]
      for (const [library_id, borrow_list] of Object.entries(books_borrowed)) {
        let isbn13_list = []
        borrow_list.forEach(x => isbn13_list.push(x['isbn13']))
        if (this.is_intersection(new Set(isbn13_list), this.isbn13_list)) {
          is_borrowing = true
          this.$store.commit("set_active_library_id", library_id)
        }
      }

      const can_borrow = get_can_borrow(this.user_id)

      if (!is_reading && is_borrowing && can_borrow) {
        return "RETURN_BOOK"
      }

      // Pick a new book
      if (this.library_id == null || (!is_reading && is_borrowing && !can_borrow)) {
        return "PICK_LIBRARY"
      }

      const library_selected = active_library_id != 0
      if (!library_selected && !is_reading) return "PICK_BOOK"
      if (!is_borrowing && can_borrow) return "BORROW_BOOK"

      return ""
    },
    is_intersection(setA, setB) {
        let _intersection = new Set()
        for (let elem of setB) {
            if (setA.has(elem)) {
                _intersection.add(elem)
            }
        }
        return _intersection.size > 0
    },
    async button_action() {
      if (this.state == "PICK_LIBRARY") this.show_pick_library_modal()
      if (this.state == "PICK_BOOK") await this.pickBook()
      if (this.state == "BORROW_BOOK") await this.pickBook()
      if (this.state == "RETURN_BOOK") await this.return_book()
    },
    async init () {
        // Do not run if user is not defined - User did refresh or logout
      if (!this.$store.getters.getUser) {
        return
      }

      // If isbn13 not scanned, get the isbn13 of the book edition
      if (this.scanned === 'true') {
        this.isbn13_scanned = this.isbn13
      }      

      // set scroll to top
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;

      // Load book
      const book = await this.$store.dispatch("load_book", this.isbn13)

      // Get tag_list filter
      this.tag_list = []
      if (this.show_nbd_labels) {
        if (book.nbd_label_age) {
          this.tag_list.push({
            img: require(`../assets/labels/nbd_age/${book.nbd_label_age}.png`)
          })
        }
        if (book.nbd_label_genre) {
          this.tag_list.push({
            img: require(`../assets/labels/nbd_genre/${book.nbd_label_genre}.png`)
          })
        }
        if (book.pim_label_category) {
          this.tag_list.push({
            img: require(`../assets/labels/pim/${book.pim_label_category}.svg`)
          })
        }
      } else {
        book.tag_list.forEach(tag => {
          if (tag.tag_name in this.tag_dict_filter) {
            const tag_filter = this.tag_dict_filter[tag.tag_name]
            if (tag.tag_relevance >= tag_filter.min_tag_relevance) {
              tag['img'] = tag_filter.img
              this.tag_list.push(tag)
            }
          }
        })
        console.log(book.tag_list)

        // Sort list
        this.tag_list.sort((a, b) => b.tag_relevance - a.tag_relevance)

        // Remove irrelevant tags
        if (this.tag_list.length > 3) {
          this.tag_list = this.tag_list.slice(0, 3)
        }
        if (this.tag_list.length == 3) {
          const relevance_1 = this.tag_list[1].tag_relevance
          const relevance_2 = this.tag_list[2].tag_relevance
          if (relevance_1 / 2 > relevance_2) {
            this.tag_list = this.tag_list.slice(0, 2)
          }
        }
        this.tag_list.reverse()
      }

      if (this.$store.getters.get_debug_mode) {
        console.log(book)
        console.log(this.tag_list)
      }

      // Parse book data
      this.book_title = book['book_title']
      this.author_name = book['author_name']
      this.page_count = book['page_count'] && book['page_count'] != 2 ? book['page_count'] + ' blz.' : '-';
      this.book_type = book['book_type'] ? book['book_type'].toLowerCase() : '-'

      if (book['min_age'] != null && book['max_age'] != null) {
        if (book['min_age'] == book['max_age']) {
          this.target_audience = book['min_age'] + ' jaar'  
        } else {
          this.target_audience = book['min_age'] + ' - ' + book['max_age'] + ' jaar'
        }       
      } else if (book['min_age'] != null) {
          this.target_audience = 'Vanaf ' + book['min_age'] + ' jaar'
      } else if (book['max_age'] != null) {
          this.target_audience = 't/m ' + book['max_age'] + ' jaar'
      }

      if (book['series_id']) {
        if (book['release_number']) {
          this.series = `Dit is deel ${book['release_number']} in de serie ${book['series_title']}.`
        } else {
          this.series = `Dit boek hoort bij de serie "${book['series_title']}"`
        }
      }
      
      if (book) {
        this.cover_url = get_book_cover(this.isbn13, 'l')
      } else {
        this.cover_url = "https://us.123rf.com/450wm/teploleta/teploleta1801/teploleta180100105/94540385-seamless-vector-pattern-with-doodle-questions-marks-on-a-blackboard-background.jpg?ver=6"
      }

      this.loaded = true

      // library
      if (this.scanned) {
        this.isbn13_list = [this.isbn13]
      } else {
        this.isbn13_list = await get_edition_isbn13_list(this.book_edition_id)
      }

      this.state = this.compute_state()

      // Get recommendations for this book
      this.tile_list_recommendations = await load_book_recommendations(this.isbn13)
    },
    show_pick_library_modal() {
      // Go back to library and then back to Overview Student
      this.$router.push({
        name: "BoekBiebKiezen",
        params: {
          'user_id': this.user_id,
          'book_edition_id': this.book_edition_id,
          'isbn13_scanned': this.isbn13_scanned,
          'action': 'lenen'
        }
      });
    },
    go_to_book_menu() {
      // Go back to library and then back to Overview Student
      this.$router.push({
        name: "MenuBoek",
        params: {
          'isbn13': this.isbn13_scanned
        }
      });
    },
    async pickBook() {

      if (this.isbn13_scanned !== 'null') {
        const result = await pick_book(this.user_id, this.isbn13_scanned)
        if (result == "No copy available") {
        } else if (this.$store.getters.getUser['user_id'] == this.user_id) {
          go_to_route("BibliotheekLezen")
        } else {
          go_to_route("GebruikersLeerlingen")
        }
      } else {
        go_to_route("BoekScanKiezen")
      }
    },
    async return_book() {
      const jwt = this.$store.getters.get_jwt
      const library_id = this.$store.getters.get_active_library_id

      const books_borrowed = this.$store.getters.get_books_borrowed[this.user_id][library_id]
      let isbn13_list_borrowed = []
      books_borrowed.forEach(x => isbn13_list_borrowed.push(x['isbn13']))
      const intersection = this.isbn13_list.filter(function(n) {
          return isbn13_list_borrowed.indexOf(n) !== -1;
      });
      const is_borrowing = intersection > 0
      if (!is_borrowing) return
      const isbn13_borrowed = intersection[0]

      const json = await get_libraries_books_copies(jwt, {
          library_id: library_id,
          user_id: this.user_id,
          isbn13: isbn13_borrowed,
          limit: 100000,
          page: 0
      })

      const copy_id = json['copy_list'][0]['copy_id']
      await put_libraries_books_copies_users_return(
          jwt,
          library_id,
          isbn13_borrowed,
          copy_id,
          this.user_id,
          this.$store.getters.get_organisation_id_active,
          get_datetime_str()
      )

      // Reload borrowed books
      await this.$store.dispatch("load_books_borrowed", {
        'jwt': jwt,
        'user_id': this.user_id
      })

      // Route user to new screen
      if (this.$store.getters.getUser['user_id'] == this.user_id) {
        setTimeout(() => go_to_route("BibliotheekLezen"), 100)
      } else {
        setTimeout(() => this.$router.back(), 100)
      }
    },
    async loadDescription() {
      // Get all books from the school library
      const request = `${get_bucket('book-descriptions')}/${this.isbn13}.txt`;

      const headers = {
        method: "get",
      };

      const response = await fetch(request, headers)
      if (response.status == '200') {
        const response_text = await response.text();
        this.summary = response_text;
      }
    },
    on_scroll() {
      this.tag_list_right_position = 40 - window.scrollY / 2
    }
  },
  async created () {
    this.init()
    this.loadDescription();
  },
  mounted () {

    // Add event listeners
    window.addEventListener('scroll', this.on_scroll);
  },
  unmounted() {
    // Remove event listeners
    window.removeEventListener('scroll', this.on_scroll);
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>


<style scoped>
/* Flex-box */
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mobile .book-info{
  z-index: 1;
}

.mobile .book-info .transition {
  background: rgb(27,23,37);
  background: linear-gradient(0deg, rgba(27,23,37,1) 0%, rgba(27,23,37,1) 63%, rgba(9,9,121,0) 100%, rgba(0,212,255,1) 100%);
  background-size: 100% 1200px;
  background-repeat: no-repeat;
  margin-top: 20vh;
  padding-top: 200px;
  height: 100%;
}

.mobile .book-info .body {
  background: rgb(27,23,37);
}

.desktop .book-info {
  min-width: 450px;
}

/* Row with details */
.book-details {
  margin-top: 40px;
  margin-bottom: 20px
}

.book-details div {
  display: flex;
  flex-direction: column;
}

.book-details div {
  text-align: center;
  height: 45px;
  justify-content: space-between;
  width: 100%;
}

.book-details div p {
  margin: 0;
}

/* v-html css */

.subtext {
  color: var(--contrast-color-p2);
}

.mobile .subtext {
  font-size: 14px;
  line-height: 1.5em;
  justify-content: space-between;
}
.desktop .subtext {
  font-size: 16px;
  line-height: 1.5em;
  justify-content: space-between;
}

.subtext >>> h1, .subtext >>> h2 {
  font-size: 18px;
}


.hide {
  z-index: -1;
  visibility: hidden;
  opacity: 0;
}

.show {
  z-index: 0;
  opacity: 1;
  visibility: visible;
}

.description {
  overflow-y: scroll;
  height: 200px;
}

.tag-tile {
  -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.40); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.40);
  width: 45px;
  height: 45px;
  background-color: white;
  border-radius: 2px;
  padding: 5px;
  margin-right: 0px;
}

</style>
